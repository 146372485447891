* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Yanone Kaffeesatz", sans-serif;
}

::selection {
  color: #fff;
  background-color: rgba(220, 53, 69, 1);
}

h1 {
  font-size: 2.5rem;
}

.chart-container {
  margin: auto;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 992px) {
  .chart-container {
    height: 400px;
    width: 800px;
  }
}

/* Statistics Table */

.table-container {
  overflow-x: auto;
}

.table-container table {
  border-collapse: collapse;
  margin: 25px auto;
  width: 70%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  overflow-x: auto;
}

.table-container table thead tr {
  background-color: rgba(220, 53, 69, 1);
  color: #ffffff;
  text-align: left;
}

.table-container table th,
.table-container table td {
  padding: 12px 18px;
}

.table-container table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.table-container table tbody tr:nth-of-type(even) {
  background-color: rgba(248, 90, 106, 0.1);
}

.table-container table tbody tr:last-of-type {
  border-bottom: 2px solid rgba(220, 53, 69, 1);
}

.pagination-buttons {
  cursor: pointer;
  width: 26px;
  height: 26px;
}

/*End of Statistics Table*/

/* Gradient for header compatible with all browsers*/

.header-breadcrumb {
  background-color: #fffdfd;
  color: rgb(58, 57, 57);
}

.header-breadcrumb a {
  color: rgb(58, 57, 57);
  margin-left: 0.25rem;
  text-decoration: none;
  transition: 300ms all;
}

.header-breadcrumb a:hover {
  color: #dc3545;
}

.header-breadcrumb a.active {
  text-decoration: none;
  color: #dc3545 !important;
}

.header,
.search .container-fluid {
  background: linear-gradient(
    90deg,
    rgba(252, 113, 113, 1) 0%,
    rgba(255, 59, 59, 1) 50%,
    rgba(220, 53, 69, 1) 100%
  );
  /* Firefox version 3.6+ */
  background: -moz-linear-gradient(
    40deg,
    rgba(252, 113, 113, 1) 0%,
    rgba(255, 59, 59, 1) 50%,
    rgba(220, 53, 69, 1) 100%
  );

  /* Safari version 5.1+, Chrome 10+ */
  background: -webkit-linear-gradient(
    40deg,
    rgba(252, 113, 113, 1) 0%,
    rgba(255, 59, 59, 1) 50%,
    rgba(220, 53, 69, 1) 100%
  );

  /* Opera version 11.10+ */
  background: -o-linear-gradient(
    40deg,
    rgba(252, 113, 113, 1) 0%,
    rgba(255, 59, 59, 1) 50%,
    rgba(220, 53, 69, 1) 100%
  );

  /* Internet Explorer Version 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FC7171', endColorstr='#DC3545', GradientType=1 );

  /* Internet Explorer Version 10+ */
  background: -ms-linear-gradient(
    40deg,
    rgba(252, 113, 113, 1) 0%,
    rgba(255, 59, 59, 1) 50%,
    rgba(220, 53, 69, 1) 100%
  );
}

@media only screen and (max-width: 768px) {
  .table-container table th span {
    display: none;
  }
}
