.contact form {
  width: 75%;
  border-radius: 0.375rem;
}

.contact form .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: rgba(220, 53, 69, 1);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 25%);
}
.contact form button {
  font-size: 1.25rem;
  width: 14rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

@media screen and (min-width: 992px) {
  .contact form {
    width: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
}
