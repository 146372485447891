.topTen .container {
  margin: auto;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 992px) {
  .topTen .container {
    height: 400px;
  }
}
