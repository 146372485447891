/* Start of Hero section */

.home .hero span {
  font-weight: 700;
  background-color: #dc3545;
  background-image: linear-gradient(45deg, #fc7171, #ff3b3b, #dc3545);
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  user-select: none;
}

.home .hero p {
  color: #737373;
}

.home .hero .home-button {
  width: 14rem;
  font-size: 1.25rem;
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

.home .hero .shape-container {
  width: 1100px;
  z-index: -1;
  position: absolute;
  top: -20rem;
  right: -25rem;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

/* End of Hero section */

/* Start of Stats section */

.home .stats {
  border-top: 0.225rem solid rgb(0 0 0 / 8%);
}

.home .stats span:nth-of-type(2) {
  box-shadow: rgba(240, 46, 46, 0.4) 5px 5px, rgba(240, 46, 46, 0.2) 9px 9px;
}

/* End of Stats section */

/* Start of Widgets section */

.home section.widgets {
  padding: 3rem 0 2rem 0;
  position: relative;
}

.home .widgets .widgets-container {
  gap: 5rem;
  flex-wrap: wrap;
}

.home .widgets .widgets-container a {
  width: 25%;
  z-index: 10;
  padding: 2.25rem 0.75rem;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  text-decoration: none;
  color: black;
  transition: 300ms all;
}

.home .widgets .widgets-container a:hover {
  background-color: #f7f6f6 !important;
  cursor: pointer;
}

.home .widgets svg.wave {
  position: absolute;
  bottom: 0;
  z-index: 0;
}

/* End of Widgets section */

/* Media Queries */
@media screen and (max-width: 1200px) {
  .home .hero .shape-container {
    right: -34rem;
  }
}

@media screen and (max-width: 992px) {
  .blob {
    right: -42rem;
  }

  .home .widgets {
    border-top: 0.225rem solid rgb(0 0 0 / 8%);
  }

  .home .widgets .widgets-container {
    flex-direction: column;
    align-items: center;
  }

  .home .widgets .widgets-container a {
    width: 75%;
  }
}

@media only screen and (max-width: 540px) {
  .home .stats {
    padding-bottom: 0.75rem !important;
  }
  .home section.widgets {
    padding: 0 0 2rem 0;
  }
  .home .widgets .widgets-container a {
    width: 100%;
  }
}
