.form-container {
  background: linear-gradient(
    210deg,
    rgba(252, 113, 113, 1) 0%,
    rgba(255, 59, 59, 1) 50%,
    rgba(220, 53, 69, 1) 100%
  );
  height: 100vh;
  display: flex;
  align-items: center;
}

.form-container form {
  width: 75%;
  box-shadow: rgba(106, 25, 25, 0.4) 5px 5px, rgba(138, 35, 35, 0.2) 9px 9px;
  border-radius: 0.375rem;
}

.form-container form .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: rgba(220, 53, 69, 1);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 25%);
}

.form-container form button {
  font-size: 1.25rem;
  width: 14rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.form-container form a {
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .form-container form {
    width: 30%;
  }
}
